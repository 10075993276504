import React, { useState } from "react";
import { Link } from "gatsby";
import { Radio } from "antd";

const ToolMenu = () => {
  return (
    <div className="toolkit">
      <Link to="/toolkit">
        <h1 style={{ marginBottom: `0` }}> Toolkit</h1>
      </Link>
      <Radio.Group >
        <Link to="/toolkit" activeStyle={{fontWeight: `bold`}}>
          <Radio.Button value="small">Toolkit Home</Radio.Button>
        </Link>
        <Link to="/toolkit/cellcosts" activeStyle={{fontWeight: `600` }}>
          <Radio.Button value="large">Battery Cost Calculator</Radio.Button>
        </Link> 
        <Link to="/toolkit/loads" activeStyle={{fontWeight: `bold`}}>
          <Radio.Button value="default">Load Calculator</Radio.Button>
        </Link>
      </Radio.Group>
    </div>
  );
};

export default ToolMenu;
